var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gst-progress-circular-animation d-flex flex-column align-center",class:{
        'complete': _vm.isComplete,
        'variant-1': _vm.variant === 1,
        'variant-2': _vm.variant === 2,
    }},[_c('IconCheckCircle',{staticClass:"gst-progress-circular-animation__icon"}),(_vm.hasContent)?_c('div',{staticClass:"gst-progress-circular-animation__content d-flex align-center justify-center",class:{
            'flex-row': _vm.variant === 1,
            'flex-column': _vm.variant === 2,
        }},[(_vm.title)?_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.description)?_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }