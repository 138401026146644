<template>
    <div class="gst-progress-circular-animation-modal d-flex flex-wrap align-center justify-center u-height-100">
        <ProgressCircularAnimation
            :bus-event="busEvent"
            :title="title"
            :description="description"
            :variant="variant"
            @complete="onCompleteDo" />
    </div>
</template>

<script>
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import ProgressCircularAnimation from '@tenants/ticketmaster/app/components/misc/ProgressCircularAnimation.vue';

    export default {
        name: 'ProgressCircularAnimationModal',
        components: {
            ProgressCircularAnimation
        },
        mixins: [ CloseModalOnRouteChangeMixin ],
        props: {
            title: {
                type: String,
                default: null
            },
            description: {
                type: String,
                default: null
            },
            variant: {
                type: Number,
                default: null
            },
            busEvent: {
                type: Object,
                required: false,
                default: null
            },
            endOnRouteChange: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            closeModal() {
                this.$emit( 'close' );
            },
            onCompleteDo() {
                this.busEvent.$emit( 'complete' );
                this.closeModal( );
            }
        },
        mounted() {
            !this.busEvent || this.busEvent.$on( 'close', this.closeModal );
            if ( this.endOnRouteChange ) {
                this.$watch( '$route', ( ) => {
                    this.busEvent.$emit( 'end' );
                } );
            }
        },
        destroyed() {
            !this.busEvent || this.busEvent.$off( 'close' );
        },
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-progress-circular-animation-modal {
        background: theme-color( 'white' );
    }
</style>