<template>
    <div
        class="gst-progress-circular-animation d-flex flex-column align-center"
        :class="{
            'complete': isComplete,
            'variant-1': variant === 1,
            'variant-2': variant === 2,
        }">
        <IconCheckCircle class="gst-progress-circular-animation__icon" />
        <div
            v-if="hasContent"
            class="gst-progress-circular-animation__content d-flex align-center justify-center"
            :class="{
                'flex-row': variant === 1,
                'flex-column': variant === 2,
            }">
            <h4 v-if="title" class="title">
                {{ title }}
            </h4>
            <p v-if="description" class="description">
                {{ description }}
            </p>
        </div>
    </div>
</template>

<script>
    import IconCheckCircle from '@core/shared/assets/icons/animation/progress_circle.svg';

    export default {
        name: 'ProgressCircularAnimation',
        components: {
            IconCheckCircle
        },
        props: {
            title: {
                type: String,
                default: null
            },
            description: {
                type: String,
                default: null
            },
            variant: {
                type: Number,
                default: 1
            },
            busEvent: {
                type: Object,
                required: false,
                default: null
            }
        },
        data() {
            return {
                isComplete: false
            };
        },
        computed: {
            hasContent() {
                return this.title || this.description;
            }
        },
        methods: {
            onEndDo() {
                this.isComplete = true;
                this.$emit( 'complete' );
            },
        },
        mounted() {
            this.busEvent && this.busEvent.$on( 'end', this.onEndDo );
        },
        destroyed() {
            this.busEvent && this.busEvent.$off( 'end' );
        },
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-progress-circular-animation {
        .animation-circle {
            animation-name: circle;
            animation-duration: 2.2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-delay: 0.25s;
        }

        .gst-progress-circular-animation__icon {
            .gst-svg-icon-progress-circle-stroke-underlay {
                stroke: theme-color( 'senary' );
            }

            .gst-svg-icon-progress-circle-checkmark {
                fill: theme-color( 'primary' );
            }

            .gst-svg-icon-progress-circle-stroke-overlay-left,
            .gst-svg-icon-progress-circle-stroke-overlay-right {
                stroke-dasharray: 227;
                stroke-dashoffset: 227;
                stroke: theme-color( 'primary' );
                transform-origin: 50% 50%;

                @extend .animation-circle;
            }

            .gst-svg-icon-progress-circle-stroke-overlay-left {
                transform: rotate( -90deg );
            }

            .gst-svg-icon-progress-circle-stroke-overlay-right {
                transform: rotate( 90deg );
            }
        }
    }

    .gst-progress-circular-animation.complete {
        .gst-progress-circular-animation__icon {
            .gst-svg-icon-progress-circle-stroke-overlay-left,
            .gst-svg-icon-progress-circle-stroke-overlay-right {
                stroke-dashoffset: 1;
                animation-duration: 0s;
                animation-delay: 0s;
            }
        }
    }

    .gst-progress-circular-animation.variant-1 {
        padding: theme-spacing( 13, 0 );

        .gst-progress-circular-animation__content {
            margin-top: theme-spacing( 10 );

            .title,
            .description {
                line-height: line-height( 'xxxl' );
                font-size: font-size( 'l' ) !important;
                font-weight: font-weight( 'regular' );
            }

            .title {
                margin-right: theme-spacing( 1 );
            }

            .description {
                margin-bottom: theme-spacing( 0 );
            }
        }
    }

    .gst-progress-circular-animation.variant-2 {
        padding: theme-spacing( 6, 0 );

        .gst-progress-circular-animation__content {
            margin-top: theme-spacing( 4 );

            .title,
            .description {
                font-weight: font-weight( 'large' );
            }

            .title {
                line-height: line-height( 'xxxxl' );
                margin-right: theme-spacing( 0 );
                font-size: font-size( 'xxxl' ) !important;
            }

            .description {
                line-height: line-height( 'xxxl' );
                margin-top: theme-spacing( 4 );
                margin-bottom: theme-spacing( 0 );
                color: theme-color( 'tertiary' );
                font-size: font-size( 'l' ) !important;
            }
        }
    }

    @keyframes circle {
        0% {
            stroke-dashoffset: 227;
        }

        50% {
            stroke-dashoffset: 1;
        }

        100% {
            stroke-dashoffset: -227;
        }
    }

    @include mobile-only {
        .gst-progress-circular-animation__content {
            h4 {
                padding-right: theme-spacing( 8 );
                padding-left: theme-spacing( 8 );
                text-align: center;
            }
        }
    }
</style>